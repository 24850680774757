import React from "react";
import Header from "../components/Header";
import LinkButton from "../components/LinkButton";
import ChevronRightIcon from "../icons/ChevronRight";

export default function PageNotFound() {
  return (
    <>
      <Header className="fixed" />
      <main className="layout-container text-center min-h-screen flex flex-col justify-center">
        <h1 className="text-3xl md:text-5xl">Page not found</h1>
        <p className="my-8">
          Sorry, but the page you requested does not exist or has been moved.
        </p>
        <div>
          <LinkButton href="/" size="large">
            <span className="mr-3">Go home</span>
            <ChevronRightIcon />
          </LinkButton>
        </div>
      </main>
    </>
  );
}

export const Head = () => <title>lasagna - page not found</title>;
